import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll'
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap'
import { useToggle } from '../hooks'

const NavBar = (props) => {
  const [isOpen, toggle] = useToggle()

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  return (
    <div>
      <Navbar color='light' light expand='md' fixed='top' style={{ padding: '1rem', maxHeight: '90px' }}>
        <img className='pointer' src='../images/sct-cc-logo-color.png' alt='sct-cc-logo' style={{ width: '160px' }} onClick={scrollToTop} />
        <NavbarToggler onClick={toggle} />
        <Collapse className='pt-3 bg-white' isOpen={isOpen} navbar>
          <Nav className='w-100 justify-content-end' navbar>
            <NavItem className='mx-3 pointer'>
              <Link
                className='nav-link font-h-regular'
                to='features'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}>
                FEATURES
              </Link>
            </NavItem>

            <NavItem className='mx-3 pointer'>
              <Link
                className='nav-link font-h-regular'
                to='videos'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}>
                VIDEOS
              </Link>
            </NavItem>

            <NavItem className='mx-3 pointer'>
              <Link
                className='nav-link font-h-regular'
                to='about'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}>
                ABOUT
              </Link>
            </NavItem>

            <NavItem className='mx-3 pointer'>
              <Link
                className='nav-link font-h-regular'
                to='contact'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}>
                CONTACT
              </Link>
            </NavItem>

            <NavItem className='mx-3 pointer mb-3'>
              <a href='https://calendly.com/surgicalcentertoolbox/surgical-center-toolbox-demo' target='_blank'>
                <button className='btn btn-primary btn-lg'>Schedule A Demo</button>
              </a>
            </NavItem>

          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default NavBar
